<template>
	<Modal name="edit-pack" :type="technic ? 'technic' : undefined">
		<template v-slot:header>
			<h1 class="title-search">
				{{ title }}
			</h1>
			<div class="row blocksubtitle">
				<div ref="serviceTitle" id="name-tech" class="form-group input-group d-flex row">
					<div class="input-group-text d-inline-block">
						{{ $t("common.name") }}
					</div>
					<input
						class="form-control d-inline-block"
						name="title"
						type="text"
						v-model="service.title"
						@input="$refs.serviceTitle.classList.remove('needed')"
					>
				</div>
				<div id="type-wrapper" class="desktop">
					<div ref="serviceTypeDesktop" class="form-group input-group d-flex">
						<div id="cate" class="input-group-text d-inline-block">
							{{ $t("common.type") }}
						</div>
						<div class="d-inline-block">
							<Popper
								class="dropdown d-block"
								trigger="clickToToggle"
								:options="{ placement: 'bottom', modifiers: { flip: { enabled: false } } }"
								:visible-arrow="false"
								ref="typeSelectorDesktop"
							>
								<div class="dropdown-menu">
									<div
										v-for="[serviceTypeID, serviceType] in Object.entries($t('data.servicestype'))"
										v-if="( serviceTypeID > 0 && !technic ) || ( serviceTypeID < 0 && technic )"
										:key="serviceTypeID"
										class="dropdown-choices"
										id="type-dropdown-choices"
										@click="$refs.typeSelectorDesktop.doClose(); $refs.serviceTypeDesktop.classList.remove('needed')"
									>
										<button
											class="dropdown-item"
											type="button"
											:value="serviceTypeID"
											@click="service.type = serviceTypeID"
										>
											{{ serviceType }}
										</button>
									</div>
								</div>
								<button
									slot="reference"
									id="type"
									aria-expanded="false"
									aria-haspopup="true"
									class="btn btn-primary dropdown-toggle"
									data-flip="false"
									data-toggle="dropdown"
									name="btn-type"
									type="button"
								>
									<template v-if="technic">
										{{ $t('data.servicestype[-1]') }}
									</template>
									<template v-else>
										{{ service.type && service.type > 0 ? $t('data.servicestype[' + service.type + ']') : '' }}
									</template>
								</button>
							</Popper>
						</div>
					</div>
				</div>
				<div v-if="$store.state.domain == 'esthetics' && !technic" id="category-wrapper" class="desktop">
					<div ref="serviceStepDesktop" class="form-group input-group d-flex">
						<div id="cate" class="input-group-text d-inline-block">
							{{ $t("common.step") }}
						</div>
						<div class="d-inline-block">
							<Popper
								class="dropdown d-block"
								trigger="clickToToggle"
								:options="{ placement: 'bottom', modifiers: { flip: { enabled: false } } }"
								:visible-arrow="false"
								ref="categorySelectorDesktop"
							>
								<div class="dropdown-menu">
									<div
										v-for="[serviceStepID, serviceStep] in servicesStepsList"
										:key="serviceStepID"
										class="dropdown-choices"
										id="category-dropdown-choices"
										@click="$refs.categorySelectorDesktop.doClose(); $refs.serviceStepDesktop.classList.remove('needed')"
									>
										<button
											class="dropdown-item"
											type="button"
											:value="serviceStepID"
											@click="service.step = serviceStepID"
										>
											{{ serviceStep }}
										</button>
									</div>
								</div>
								<button
									slot="reference"
									id="category"
									aria-expanded="false"
									aria-haspopup="true"
									class="btn btn-primary dropdown-toggle"
									data-flip="false"
									data-toggle="dropdown"
									name="btn-category"
									type="button"
								>
									{{ service.step ? servicesSteps[service.step] : '' }}
								</button>
							</Popper>
						</div>
					</div>
				</div>
				<ToggleSwitch v-if="$store.getters.salonHasStocks() && !technic" placement="before" v-model="service.uses_stock" class="desktop">
					{{$t("common.utilstock")}}
				</ToggleSwitch>
			</div>
		</template>
		<template v-slot:content>
			<div class="mobile mobile-dropdowns row">
				<div id="type-wrapper" class="mobile" :class="'col-sm-' + ( $store.state.domain == 'esthetics' ? '6' : '12' )">
					<div ref="serviceTypeMobile" class="form-group input-group d-flex">
						<div id="cate" class="input-group-text d-inline-block">
							{{ $t("common.type") }}
						</div>
						<div class="d-inline-block dropdown-button-body">
							<Popper
								class="dropdown d-block"
								trigger="clickToToggle"
								:options="{ placement: 'bottom', modifiers: { flip: { enabled: false } } }"
								:visible-arrow="false"
								ref="typeSelectorMobile"
							>
								<div class="dropdown-menu">
									<div
										v-for="[serviceTypeID, serviceType] in Object.entries($t('data.servicestype'))"
										v-if="( serviceTypeID > 0 && !technic ) || ( serviceTypeID < 0 && technic )"
										:key="serviceTypeID"
										class="dropdown-choices"
										id="type-dropdown-choices"
										@click="$refs.typeSelectorMobile.doClose(); $refs.serviceTypeMobile.classList.remove('needed')"
									>
										<button
											class="dropdown-item"
											type="button"
											:value="serviceTypeID"
											@click="service.type = serviceTypeID"
										>
											{{ serviceType }}
										</button>
									</div>
								</div>
								<button
									slot="reference"
									id="type"
									aria-expanded="false"
									aria-haspopup="true"
									class="btn btn-primary dropdown-toggle"
									data-flip="false"
									data-toggle="dropdown"
									name="btn-type"
									type="button"
								>
									<template v-if="technic">
										{{ $t('data.servicestype[-1]') }}
									</template>
									<template v-else>
										{{ service.type && service.type > 0 ? $t('data.servicestype[' + service.type + ']') : '' }}
									</template>
								</button>
							</Popper>
						</div>
					</div>
				</div>
				<div v-if="$store.state.domain == 'esthetics' && !technic" id="category-wrapper" class="mobile col-sm-6">
					<div ref="serviceStepMobile" class="form-group input-group d-flex">
						<div id="cate" class="input-group-text d-inline-block">
							{{ $t("common.step") }}
						</div>
						<div class="d-inline-block dropdown-button-body">
							<Popper
								class="dropdown d-block"
								trigger="clickToToggle"
								:options="{ placement: 'bottom', modifiers: { flip: { enabled: false } } }"
								:visible-arrow="false"
								ref="categorySelectorMobile"
							>
								<div class="dropdown-menu">
									<div
										v-for="[serviceStepID, serviceStep] in servicesStepsList"
										:key="serviceStepID"
										class="dropdown-choices"
										id="category-dropdown-choices"
										@click="$refs.categorySelectorMobile.doClose(); $refs.serviceStepMobile.classList.remove('needed')"
									>
										<button
											class="dropdown-item"
											type="button"
											:value="serviceStepID"
											@click="service.step = serviceStepID"
										>
											{{ serviceStep }}
										</button>
									</div>
								</div>
								<button
									slot="reference"
									id="category"
									aria-expanded="false"
									aria-haspopup="true"
									class="btn btn-secondary dropdown-toggle"
									data-flip="false"
									data-toggle="dropdown"
									name="btn-category"
									type="button"
								>
									{{ service.step ? servicesSteps[service.step] : '' }}
								</button>
							</Popper>
						</div>
					</div>
				</div>
				<div class="stocks-button-mobile mobile">
					<ToggleSwitch v-if="$store.getters.salonHasStocks() && !technic" placement="before" v-model="service.uses_stock">
						{{$t("common.utilstock")}}
					</ToggleSwitch>
				</div>
			</div>
			<div
				v-if="technic"
				class="text-center text-grey text-muted font-italic"
			>
				{{ $t("administration.onlyweb") }}
			</div>
			<div class="input-group form-group">
				<div class="block1 row" />
			</div>
			<form class="form">
				<div class="input-group form-group dependencies">
					<h2 class="title-search2-2 message">
						{{ $t("modals.editservice.description") }}
					</h2>
				</div>
				<div id="description" class="input-group form-group">
					<textarea v-model="service.description" :placeholder="$t('common.optional')"/>
				</div>
				<ToggleSwitch
					v-if="!technic && $store.getters.salonPrestationsHasLength()"
					:value="!service.nolength"
					@change="service.nolength = !$event"
					id="edit-service-toggle-hairlength"
				>{{$t("modals.editservice.hairlength")}}</ToggleSwitch>
				<div v-if="technic == false" :class="'form-group' + ( missingtime && !hasTime() ? ' required' : '' )">
					<div class="input-group form-group">
						<h2 class="title-search2-2">
							{{ $t("common.duration") }}
						</h2>
					</div>
					<div class="input-group form-group" v-if="$store.getters.salonPrestationsHavePauses()">
						<div class="consign text-grey text-muted font-italic">{{ $t("administration.time") }}<br><br>{{ $t("administration.time2") }}</div>
					</div>
					<div class="input-group form-group">
						<div style="margin: auto; width: 100%">
							<ValidationInput
								:class="{ 'duration-input': true, 'no-pause': true }"
								class="form-control"
								style="margin: auto; width: 5rem"
								type="positiveInt" nullable
								v-model="service.female_short[1]"
								@input="service.female_short[2] = $event"
								@valid="valid['female_short' + 0] = $event"
								data-input-duration="female-short"
								:data-input-duration-index="0"
							/>
							<!-- {{service.female_short}} -->
							<!-- <span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span> -->
							<div class="total-time" name="f_short[2]" v-if="$store.getters.salonPrestationsHavePauses()">
								{{ $t("administration.total") + " " + service.female_short.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
							</div>
						</div>
						<!-- <table id="temps">
							<thead>
								<tr>
									<th v-if="!service.nolength">{{ $t("common.hair") }}</th>
									<th>{{ $t("common.wom") }}</th>
									<th class="desktop">{{ $t("common.men") }}</th>
									<th class="desktop" v-if="$store.getters.salonAcceptsChildren()">{{ $t("common.youth") }}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td v-if="!service.nolength" class="length">{{ $t("data.hairlength.short") }}</td>
									<td>
										<div>
											<ValidationInput
												v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
												:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
												:key="i" class="form-control"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.female_short[i + 1]"
												@valid="valid['female_short' + i] = $event"
												data-input-duration="female-short"
												:data-input-duration-index="i"
											/>
											<span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span>
											<div class="total-time" name="f_short[2]" v-if="$store.getters.salonPrestationsHavePauses()">
												{{ $t("administration.total") + " " + service.female_short.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</div>
									</td>
									<td class="desktop">
										<div>
											<ValidationInput
												v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
												:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
												:key="i" class="form-control"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.male_short[i + 1]"
												@valid="valid['male_short' + i] = $event"
												data-input-duration="male-short"
												:data-input-duration-index="i"
											/>
											<span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span>
											<div class="total-time" name="h_short[2]" v-if="$store.getters.salonPrestationsHavePauses()">
												{{ $t("administration.total") + " " + service.male_short.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</div>
									</td>
									<td class="desktop" :rowspan="service.nolength ? 1 : 3" v-if="$store.getters.salonAcceptsChildren()">
										<div>
											<ValidationInput
												v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
												:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
												:key="i" class="form-control"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.child[i + 1]"
												@valid="valid['child' + i] = $event"
												data-input-duration="child"
												:data-input-duration-index="i"
											/>
											<span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span>
											<div class="total-time" name="enf[2]" v-if="$store.getters.salonPrestationsHavePauses()">
												{{ $t("administration.total") + " " + service.child.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</div>
									</td>
								</tr>
								<tr v-if="!service.nolength">
									<td class="length">{{ $t("data.hairlength.half") }}</td>
									<td>
										<div>
											<ValidationInput
												v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
												:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
												:key="i"
												class="form-control"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.female_half[i + 1]"
												@valid="valid['female_half' + i] = $event"
												data-input-duration="female-half"
												:data-input-duration-index="i"
											/>
											<span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span>
											<div class="total-time" name="f_half[2]" v-if="$store.getters.salonPrestationsHavePauses()">
												{{ $t("administration.total") + " " + service.female_half.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</div>
									</td>
									<td class = "desktop "/>
								</tr>
								<tr v-if="!service.nolength">
									<td class="length">{{ $t("data.hairlength.long") }}</td>
									<td>
										<div>
											<ValidationInput
												v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
												:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
												:key="i"
												class="form-control"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.female_long[i + 1]"
												@valid="valid['female_long' + i] = $event"
												data-input-duration="female-long"
												:data-input-duration-index="i"
											/>
											<div class="total-time"	name="f_long[2]" v-if="$store.getters.salonPrestationsHavePauses()">
												{{ $t("administration.total") + " " + service.female_long.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</div>
									</td>
									<td  class="desktop">
										<div>
											<ValidationInput
												v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
												:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
												:key="i"
												class="form-control"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.male_long[i + 1]"
												@valid="valid['male_long' + i] = $event"
												data-input-duration="male-long"
												:data-input-duration-index="i"
											/>
											<span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span>
											<div class="total-time" name="h_long[2]" v-if="$store.getters.salonPrestationsHavePauses()">
												{{ $t("administration.total") + " " + service.male_long.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</div>
									</td>
								</tr>
								<tr class = "mobile">
									<th v-if="!service.nolength" class = "thead" >{{ $t("common.hair") }}</th>
									<th class = "thead" >{{ $t("common.men") }}</th>
								</tr>
								<tr class = "mobile">
									<td v-if="!service.nolength" class="length">{{ $t("data.hairlength.short") }}</td>
									<td>
										<div>
											<ValidationInput
												v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
												:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
												:key="i"
												class="form-control"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.male_short[i + 1]"
												@valid="valid['male_short' + i] = $event"
												data-input-duration="male-short"
												:data-input-duration-index="i"
											/>
											<span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span>
											<div class="total-time" name="h_short[2]" v-if="$store.getters.salonPrestationsHavePauses()">
												{{ $t("administration.total") + " " + service.male_short.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</div>
									</td>
								</tr>
								<tr v-if="!service.nolength" class = "mobile">
									<td class="length">{{ $t("data.hairlength.long") }}</td>
									<td>
										<div>
											<ValidationInput
												v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
												:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
												:key="i"
												class="form-control"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.male_long[i + 1]"
												@valid="valid['male_long' + i] = $event"
												data-input-duration="male-long"
												:data-input-duration-index="i"
											/>
											<span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span>
											<div class="total-time" name="h_long[2]" v-if="$store.getters.salonPrestationsHavePauses()">
												{{ $t("administration.total") + " " + service.male_long.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</div>
									</td>
								</tr>
								<tr class = "mobile" v-if="$store.getters.salonAcceptsChildren()">
									<th v-if="!service.nolength" class = "thead" >{{ $t("common.hair") }}</th>
									<th class = "thead" >{{ $t("common.youth") }}</th>
								</tr>
								<tr class = "mobile" v-if="$store.getters.salonAcceptsChildren()">
									<td v-if="!service.nolength" class="length"></td>
									<td>
										<ValidationInput
											v-for="i in ($store.getters.salonPrestationsHavePauses() ? 5 : 1)"
											:class="{ 'duration-input': true, 'no-pause': !$store.getters.salonPrestationsHavePauses() }"
											:key="i"
											class="form-control"
											type="positiveInt" nullable
											:validate="i => i >= 5"
											v-model="service.child[i + 1]"
											@valid="valid['child' + i] = $event"
											data-input-duration="child"
											:data-input-duration-index="i"
										/>
										<span class="min-suffix" v-if="!$store.getters.salonPrestationsHavePauses()">{{ $t('time.min') }}</span>
										<div class="total-time" name="child[2]" v-if="$store.getters.salonPrestationsHavePauses()">
											{{ $t("administration.total") + " " + service.child.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
										</div>
									</td>
								</tr>
							</tbody>
						</table> -->
					</div>
					<div class="input-group form-group alternates" v-if="false && $store.getters.salonPrestationsHasAlt()">
						<ToggleSwitch
							@change="$refs.alternates.classList.toggle( 'visible' )"
							:value="alternates"
						>
							<div class="title"><span>{{ $t("modals.editservice.alternates") }}</span></div>
							<div class="text-muted ">{{ $t("modals.editservice.alternatessub") }}</div>
						</ToggleSwitch>
						<div ref="alternates" id="alternates" :class="( alternates ) ? 'visible' : ''">
							<table>
								<thead>
									<tr>
										<th>{{ $t("common.hair") }}</th>
										<!--th>{{ $t("common.price") }}</th-->
										<th>{{ $t("common.duration") }}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="length">{{ $t("data.hairlength.short") }}</td>
										<!--td>
											<div>
												<div class="price">
													<input class="form-control" type="number" v-model="service.alt_female_short[0]">
												</div>
											</div>
										</td-->
										<td>
											<ValidationInput
												v-for="i in 5"
												:key="i"
												class="form-control duration-input"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.alt_female_short[i + 1]"
												@valid="valid['alt_female_short' + i] = $event"
												data-input-duration="alt-female-short"
												:data-input-duration-index="i"
											/>
											<div class="total-time" name="f_short[2]">
												{{ $t("administration.total") + " " + service.alt_female_short.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</td>
									</tr>
									<tr>
										<td class="length">{{ $t("data.hairlength.half") }}</td>
										<!--td>
											<div>
												<div class="price">
													<input class="form-control" type="number" v-model="service.alt_female_half[0]">
												</div>
											</div>
										</td-->
										<td>
											<ValidationInput
												v-for="i in 5"
												:key="i"
												class="form-control duration-input"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.alt_female_half[i + 1]"
												@valid="valid['alt_female_half' + i] = $event"
												data-input-duration="alt-female-half"
												:data-input-duration-index="i"
											/>
											<div class="total-time" name="f_half[2]">
												{{ $t("administration.total") + " " + service.alt_female_half.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</td>
									</tr>
									<tr>
										<td class="length">{{ $t("data.hairlength.long") }}</td>
										<!--td>
											<div>
												<div class="price">
													<input class="form-control" type="number" v-model="service.alt_female_long[0]">
												</div>
											</div>
										</td-->
										<td>
											<ValidationInput
												v-for="i in 5"
												:key="i"
												class="form-control duration-input"
												type="positiveInt" nullable
												:validate="i => i >= 5"
												v-model="service.alt_female_long[i + 1]"
												@valid="valid['alt_female_long' + i] = $event"
												data-input-duration="alt-female-long"
												:data-input-duration-index="i"
											/>
											<div class="total-time"	name="f_long[2]">
												{{ $t("administration.total") + " " + service.alt_female_long.slice(2).reduce( ( a, b ) => a + (parseInt(b) || 0), 0 ) + " " + $t("common.min") }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div
					class="form-group"
					:class="{ required: ( missingprice && ( !technic || !service.only_quote ) && !service.male_short[0] && !service.female_short[0] && !service.child[0] ) }"
				>
					<div class="input-group form-group">
						<h2 class="title-search2">
							{{ $t("administration.price") }}
						</h2>
					</div>
					<div id="tarifs-wrapper" :class="`input-group form-group${technic ? ' technic' : ''}${service.only_quote ? ' hidden' : ''}`">
						<!-- <table id="tarifs">
							<thead>
								<tr>
									<th v-if="!technic && !service.nolength">{{ $t("common.hair") }}</th>
									<th>{{ $t("common.wom") }}</th>
									<th class="desktop">{{ $t("common.men") }}</th>
									<th class="desktop" v-if="$store.getters.salonAcceptsChildren()">{{ $t("common.youth") }}</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td v-if="!technic && !service.nolength" class="length">{{ $t("data.hairlength.short") }}</td>
									<td> -->
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.female_short[0]"
												@valid="valid['female_short[0]'] = $event"
											/>
										</div>
									<!-- </td>
									<td class="desktop">
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.male_short[0]"
												@valid="valid['male_short[0]'] = $event"
											/>
										</div>
									</td>
									<td
										class="desktop"
										:rowspan="!technic && !service.nolength && $store.getters.salonAcceptsChildren() ? 3 : 1"
										v-if="$store.getters.salonAcceptsChildren()"
									>
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.child[0]"
												@valid="valid['child[0]'] = $event"
											/>
										</div>
									</td>
								</tr>
								<tr v-if="!technic && !service.nolength">
									<td class="length">{{ $t("data.hairlength.half") }}</td>
									<td>
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.female_half[0]"
												@valid="valid['female_half[0]'] = $event"
											/>
										</div>
									</td>
									<td  class="desktop" />
								</tr>
								<tr v-if="!technic && !service.nolength">
									<td class="length">{{ $t("data.hairlength.long") }}</td>
									<td>
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.female_long[0]"
												@valid="valid['female_long[0]'] = $event"
											/>
										</div>
									</td>
									<td  class="desktop">
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.male_long[0]"
												@valid="valid['male_long[0]'] = $event"
											/>
										</div>
									</td>
								</tr>
								<tr class= "mobile thead">
									<th v-if="!technic && !service.nolength">{{ $t("common.hair") }}</th>
									<th>{{ $t("common.men") }}</th>
								</tr>
								<tr class = "mobile">
									<td v-if="!technic && !service.nolength" class="length">{{ $t("data.hairlength.short") }}</td>
									<td>
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.male_short[0]"
												@valid="valid['male_short[0]'] = $event"
											/>
										</div>
									</td>
								</tr>
								<tr v-if="!technic && !service.nolength" class = "mobile">
									<td class="length">{{ $t("data.hairlength.long") }}</td>
									<td>
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.male_long[0]"
												@valid="valid['male_long[0]'] = $event"
											/>
										</div>
									</td>
								</tr>
								<tr class= "mobile thead" v-if="$store.getters.salonAcceptsChildren()">
									<th v-if="!technic && !service.nolength">{{ $t("common.hair") }}</th>
									<th>{{ $t("common.young") }}</th>
								</tr>
								<tr class = "mobile" v-if="$store.getters.salonAcceptsChildren()">
									<td v-if="!technic && !service.nolength" class="length"></td>
									<td>
										<div class="price">
											<ValidationInput
												class="form-control price-input"
												type="positiveReal" nullable
												v-model="service.child[0]"
												@valid="valid['child[0]'] = $event"
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</table> -->
					</div>
					<ToggleSwitch v-if="technic" v-model="service.only_quote" class="onlyquote"><i>{{$t("modals.editservice.onlyquote")}}</i></ToggleSwitch>
				</div>
				<template v-if="technic == false && $store.getters.salonPrestationsHasDependencies()">
					<div class="input-group form-group dependencies">
						<h2 class="title-search2-3">
							{{ $t("common.dependencies") }}
						</h2>
					</div>
					<div class="input-group form-group">
						<div id="prestation-group">
							<div id="prestations-cont">
								<!-- NOTE for next versions : allow manually ordering services (which will over-ride standard sorting) -->
								<!-- Draggable v-model="service.dependencies" :scroll-sensitivity="100" :force-fallback="true" v-on:start="drag=true" v-on:end="drag=false" class="ui-sortable" handle=".sortable"-->
								<div class="d-flex d-justify-content-center">
									<ServiceButton
										v-for="dep in dependenciesResolved"
										v-if="!!dep"
										:key="dep.id" :id="'dependency-' + dep.id"
										:service="dep"
										@remove="service.dependencies.splice( service.dependencies.indexOf( dep.id ), 1 )"
										:hide="service.dependencies"
									/>
								</div>
								<!--/Draggable-->
								<ServiceButton
									@selected="service.dependencies.push( $event.id ); service.dependencies.sort( ( a, b ) => this.$store.state.dependencies.find(s => s.id == a).priority - this.$store.state.dependencies.find(s => s.id == b).priority )"
									:hide="service.dependencies"
								/>
							</div>
						</div>
					</div>
				</template>
				<div class="input-group form-group dependencies">
					<h2 class="title-search2-2 message">
						{{ $t("modals.editservice.message") }}
					</h2>
				</div>
				<div id="message" class="input-group form-group">
					<div class="text-muted">{{ $t('modals.editservice.questiontext') }}</div>
					<textarea v-model="service.message" :placeholder="$t('modals.editservice.question')"/>
				</div>
			</form>
		</template>
		<template #buttons>
			<StatusButton v-if="prefillData && Object.keys(prefillData).length > 0" id="delete" type="danger" alert="modal" v-on:click="showDeleteConfirm = $event"><i class="fas fa-trash-alt"></i></StatusButton>
			<StatusButton id="validate" type="primary" alert="modal" @click="save( $event, true )"><i class="fas fa-check"></i></StatusButton>
			<StatusButton class="desktop" id="apply" type="secondary" alert="modal" @click="save( $event, false )">{{$t("common.apply")}}</StatusButton>
			<button id="cancel" class="btn btn-gray" @click="$emit('close')" type="button">{{$t( dataChanged ? "common.cancel" : "common.close")}}</button>
			<Confirm v-if="showDeleteConfirm" ref="deleteConfirm">
				{{ $t('pack.confirmdelete') }}
			</Confirm>
		</template>
	</Modal>
</template>

<script>
	import Modal from "../components/Modal.vue";
	import Popper from "vue-popperjs"
	import ServiceButton from '../components/ServiceButton.vue'
	import StatusButton from '../components/StatusButton.vue'
	import ToggleSwitch from '../components/ToggleSwitch.vue'
	import Draggable from 'vuedraggable'
	import Confirm from "../modals/Confirm.vue"

	export default {
		components: {
			Modal,
			StatusButton,
			ToggleSwitch,
			ServiceButton,
			Draggable,
			Confirm,
			Popper
		},
		props: ["title", "technic", "prefillData"],
		data() {
			return {
				showDeleteConfirm: false,
				dataChanged: false,
				valid: {},
				service: Object.assign( {
					title: '',
					type: null,
					step: null,
					nolength: this.$store.state.salon.type != "hairdresser",
					// [price, duration, (work time, break time,)*]
					male_short: [],
					male_half: [],
					male_long: [],
					female_short: [],
					female_half: [],
					female_long: [],
					child: [],
					dependencies: [],
					alt_female_short: [],
					alt_female_half: [],
					alt_female_long: [],
					uses_stock: false,
					only_quote: false,
					description: null,
					message: null
				}, JSON.parse( JSON.stringify( this.prefillData ) ) ),
				missingprice: false,
				missingtime: false,
				prefillService: JSON.parse( JSON.stringify( this.prefillData ) )
			}
		},
		computed: {
			servicesStepsList() {
				if ( this.$store.state.domain != "esthetics" ) {
					return []
				}
				return this.$store.getters.salonTypes().map( type => Object.entries(this.$t('data.servicesstep')[type]) ).flat()
			},
			servicesSteps() {
				return this.$store.getters.salonTypes().map( type => this.$t('data.servicesstep')[type] ).reduce( (a, b) => ({ ...a, ...b }), {} )
			},
			dependenciesResolved() {
				console.log(this.service.dependencies, this.service.dependencies.map(id => this.$store.state.services.find(s => s.id == id)))
				return this.service.dependencies.map(id => this.$store.state.services.find(s => s.id == id))
			},
			alternates() {
				if ( this.service.alt_female_short.length === 0 && this.service.alt_female_half.length === 0 && this.service.alt_female_long.length === 0 ) {
					return false
				}
				if ( this.service.alt_female_short[0] || this.service.alt_female_short[1]
					 || this.service.alt_female_half[0] || this.service.alt_female_half[1]
					 || this.service.alt_female_long[0] || this.service.alt_female_long[1] ) {
					return true
				}
				return false
			}
		},
		watch: {
			"service.nolength"( value ) {
				console.log( "nolength", value )
				if ( value ) {
					this.service.female_half = [ ...this.service.female_short ]
					this.service.female_long = [ ...this.service.female_short ]
					this.service.male_half = [ ...this.service.male_short ]
					this.service.male_long = [ ...this.service.male_short ]
				}
			},
			"service.female_short": {
				handler( value ) {
					if ( value && this.service.nolength ) {
						this.service.female_half = [ ...value ]
						this.service.female_long = [ ...value ]
					}
				},
				deep: true
			},
			"service.male_short": {
				handler( value ) {
					if ( value && this.service.nolength ) {
						this.service.male_half = [ ...value ]
						this.service.male_long = [ ...value ]
					}
				},
				deep: true
			},
			showDeleteConfirm( callback ) {
				if ( callback !== false ) {
					this.$nextTick( () => {
						this.$refs.deleteConfirm.$on( "confirm", () => {
							this.$refs.deleteConfirm.$off( 'confirm' );
							this.$refs.deleteConfirm.$off( 'close' );
							this.showDeleteConfirm = false;
							this.deleteService( callback );
						});
						this.$refs.deleteConfirm.$on( "close", () => {
							this.$refs.deleteConfirm.$off( 'confirm' );
							this.$refs.deleteConfirm.$off( 'close' );
							this.showDeleteConfirm = false;
							callback( -1 );
						});
					});
				}
			}
		},
		mounted() {
			if ( this.$store.state.domain == "coachs" ) {
				this.service.female_short = this.service.female || []
				this.service.male_short = this.service.male || []
				if ( this.prefillService ) {
					this.prefillService.female_short = this.prefillService.female || []
					this.prefillService.male_short = this.prefillService.male || []
				}
			}
		},
		methods: {
			hasTime() {
				let x = 0
				x += this.service.female_short.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				x += this.service.female_half.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				x += this.service.female_long.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				x += this.service.male_short.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				x += this.service.male_half.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				x += this.service.male_long.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				x += this.service.child.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				return !isNaN(x) && x > 0
			},
			async deleteService( callback ) {
				try {
					const formulas = this.$store.getters.getFormulas()
					if ( formulas.some(f => f.services.includes(this.prefillService.id)) ) {
						callback(false, this.$t('modals.editservice.hasdependency'))
						return
					}
					const services = this.$store.getters.getServices()
					if ( services.some(s => s.dependencies && s.dependencies.includes(this.prefillService.id)) ) {
						// TODO : propose to the user to unlink the dependency
						callback(false, this.$t('errors.service.breaksdependency'))
						return
					}
					await this.$api.service.delete(this.prefillService.id)
					callback(true)
					setTimeout(() => this.$emit('close'), 1250)
				} catch (error) {
					console.log(error)
					callback(false, error.response.data.error)
				}
			},
			save( callback, closeAfter ) {
				// this.service.female_short = this.service.female_short.map( val => parseInt( val ) || undefined )
				// this.service.female_half = this.service.female_half.map( val => parseInt( val ) || undefined )
				// this.service.female_long = this.service.female_long.map( val => parseInt( val ) || undefined )
				// this.service.male_short = this.service.male_short.map( val => parseInt( val ) || undefined )
				// this.service.male_half = this.service.male_half.map( val => parseInt( val ) || undefined )
				// this.service.male_long = this.service.male_long.map( val => parseInt( val ) || undefined )
				// this.service.child = this.service.child.map( val => parseInt( val ) || undefined )
				// this.service.alt_female_short = this.service.alt_female_short.map( val => parseInt( val ) || undefined )
				// this.service.alt_female_half = this.service.alt_female_half.map( val => parseInt( val ) || undefined )
				// this.service.alt_female_long = this.service.alt_female_long.map( val => parseInt( val ) || undefined )
				// this.service.female_short[1] = this.service.female_short.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.female_long[1] = this.service.female_long.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.female_half[1] = this.service.female_half.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.male_short[1] = this.service.male_short.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.male_half[1] = this.service.male_half.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.male_long[1] = this.service.male_long.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.child[1] = this.service.child.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.alt_female_short[1] = this.service.alt_female_short.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.alt_female_half[1] = this.service.alt_female_half.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )
				// this.service.alt_female_long[1] = this.service.alt_female_long.slice(2).reduce( ( accum, val ) => accum + ( parseInt(val) || 0 ), 0 )

				console.log("valid", this.valid)
				if (!Object.values(this.valid).every(val => val)) {
					console.log("not valid")
					callback(-1)
					return
				}

				let dependencies = this.service.dependencies

				const needed = [ "title" ]
				if ( !this.technic ) {
					needed.push( "type" )
					if ( this.$store.state.domain == "esthetics" ) {
						needed.push( "step" )
					}
				}
				let checkFailed = false
				needed.forEach( field => {
					if ( !this.service[field] || this.service[field].length === 0 ) {
						const elem = this.$refs['service' + field.charAt(0).toUpperCase() + field.slice(1)]
						if ( elem ) {
							elem.classList.add('needed')
						} else {
							const elemD = this.$refs['service' + field.charAt(0).toUpperCase() + field.slice(1) + 'Desktop']
							const elemM = this.$refs['service' + field.charAt(0).toUpperCase() + field.slice(1) + 'Mobile']
							if ( elemD ) {
								elemD.classList.add('needed')
							}
							if ( elemM ) {
								elemM.classList.add('needed')
							}
						}
						checkFailed = true
					}
				})
				/*
				if ( ( !this.technic || !this.service.only_quote ) && !this.service.male_short[0] && !this.service.female_short[0] && !this.service.child[0] ) {
					this.missingprice = true
					checkFailed = true
				}
				*/
				if ( !this.technic && !this.hasTime() ) {
					this.missingtime = true
					checkFailed = true
				}
				if ( checkFailed ) {
					// callback( false )
					// return
				}

				if ( this.prefillService ) {
					const previousDependencies = ( this.prefillService.dependencies || [] ).map( dep => dep.id || dep )
					let patch = {}
					Object.keys( this.service ).forEach( key => {
						const isArr = Array.isArray(this.service[key])
						if ( key !== "dependencies" && ( ( isArr && !this.service[key].isSame( this.prefillService[key] ) ) || ( !isArr && this.service[key] != this.prefillService[key] ) ) ) {
							patch[key] = this.service[key]
						}
					})
					if ( !dependencies.isSame( previousDependencies ) ) {
						patch.dependencies = dependencies
					}
					if ( this.$store.state.domain == "coachs" ) {
						if ( patch.male_short ) {
							patch.male = [ ...patch.male_short ]
						}
						if ( patch.female_short ) {
							patch.female = [ ...patch.female_short ]
						}
						delete patch.step
						delete patch.nolength
						delete patch.male_short
						delete patch.male_half
						delete patch.male_long
						delete patch.female_short
						delete patch.female_half
						delete patch.female_long
						delete patch.alt_female_short
						delete patch.alt_female_half
						delete patch.alt_female_long
						delete patch.dependencies
						delete patch.uses_stock
					}
					if ( Object.keys( patch ).length > 0 ) {
						this.$api.service.patch( this.service.id, patch ).then( response => {
							callback( true )
							if ( closeAfter ) {
								setTimeout( () => this.$emit( 'close' ), 1250 )
							} else {
								this.prefillService = JSON.parse( JSON.stringify( this.service ) )
							}
						}).catch( error => {
							console.log( error )
							callback( false, error.response.data.error )
						})
					} else if ( closeAfter ) {
						this.$emit( 'close' )
					} else {
						callback( -1 )
					}
				} else {
					let post = Object.assign( JSON.parse( JSON.stringify( this.service ) ), {
						dependencies: dependencies,
						show_website: true
					} )
					if ( this.technic ) {
						post.type = -1
					}
					if ( this.$store.state.domain == "coachs" ) {
						post.male = [ ...( post.male_short || [] ) ]
						post.female = [ ...( post.female_short || [] ) ]
						delete post.step
						delete post.nolength
						delete post.male_short
						delete post.male_half
						delete post.male_long
						delete post.female_short
						delete post.female_half
						delete post.female_long
						delete post.alt_female_short
						delete post.alt_female_half
						delete post.alt_female_long
						delete post.dependencies
						delete post.uses_stock
					}
					this.$api.services.post( post ).then( response => {
						callback( true )
						if ( closeAfter ) {
							setTimeout( () => this.$emit( 'close' ), 1250 )
						} else {
							this.prefillService = JSON.parse( JSON.stringify( this.service ) )
						}
					}).catch( error => {
						console.log( error )
						callback( false, error.response.data.error )
					})
				}
			}
		}
	};
</script>

<style scoped lang="scss" src="../css/modals/editservice.scss" />
