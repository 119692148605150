<template>
	<div :class="'service-dependency' + ( service ? ' sortable' : ' new' )">
		<div :class="service ? 'btn dependency' : 'btn-new-dependency'">
			<template v-if="service">
				{{ service.title }}
				<span class="dep-remove" @click="$emit( 'remove', service )">
					<i class="fas fa-times-circle"></i>
				</span>
			</template>
			<template v-else>
			
				<Popper
					class="dropdown d-block"
					trigger="clickToToggle"
					:options="{ placement: 'top', modifiers: [{ name: 'preventOverflow', options: { mainAxis: false } }] }"
					:visible-arrow="false"
					ref="selector"
					@show="/*$nextTick( () => { $refs.searchInput.focus() })*/"
				>
					<div class="dropdown-menu" data-service-button-popper>
						<div class="desktop">
							<input ref="searchInput" class="dropdown-searchbox form-control" placeholder="Rechercher..." type="text" v-model="filter">
							<i class="fas fa-backspace" @click="filter = ''"></i>
						</div>
						<div
							class="dropdown-choices"
							@click="$refs.selector.doClose()"
						>
							<button
								v-for="service in searchResults"
								class="dropdown-item"
								type="button"
								@click="$emit( 'selected', service ); filter = ''"
							>
								{{ service.title }}
							</button>
						</div>
					</div>
					<button
						slot="reference"
						id="category"
						aria-expanded="false"
						aria-haspopup="true"
						class="btn btn-secondary dropdown-toggle"
						data-flip="false"
						data-toggle="dropdown"
						name="btn-category"
						type="button"
					>
						{{$t("common.add")}}
					</button>
				</Popper>
			</template>
		</div>
	</div>
</template>

<script>
	import Popper from 'vue-popperjs'

	export default {
		components: {
			Popper
		},
		props: {
			service: { type: Object, default: null },
			hide: { type: Array, default: () => [] },
			showTechnic: { type: Boolean, default: false },
		},
		data() {
			return {
				filter: '',
			}
		},
		computed: {
			services() {
				return this.$store.state.services
					.filter(s => !this.hide.includes(s.id))
					.filter(s => this.showTechnic || s.type >= 0)
			},
			searchResults() {
				return this.services.filter(s => s.title.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
			}
		},
	}
</script>
